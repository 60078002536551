<template>
  <div class="tasks-wrap">
    <template v-if="state.showList.length">
      <a-collapse
        v-model:activeKey="collapseKey"
        expandIconPosition="right"
        :bordered="false"
      >
        <a-collapse-panel
          v-for="(item, index) in state.showList"
          :key="index + ''"
        >
          <template #header>
            <div class="tasks-tit">
              <span class="pr10">{{ item.taskName }}</span>
              <span class="pr10">
                ({{ dateFormat(item.startTime) }} -
                {{ dateFormat(item.endTime) }})
              </span>
              <div style="width: 150px; margin-left: 30px">
                <a-progress :percent="item.progress" size="small" />
              </div>
            </div>
          </template>
          <div class="stages-box">
            <div
              class="stages-item"
              v-for="(stage, stageIndex) in item.stages"
              :key="stageIndex"
            >
              <div class="stages-tit">
                <span class="pr10">{{ stageIndex + 1 }}</span>
                <span class="pr10">{{ stage.stageName }}</span>
                <span>({{ stage.progress }}%)</span>
              </div>
              <div class="details">
                <div
                  class="details-item"
                  v-for="detail in stage.details"
                  :key="detail.detailId"
                >
                  <div>{{ $t(getDetailType(detail.resourceType) || "") }}</div>
                  <div>
                    <a-tooltip :title="detail.detailName">{{
                      detail.detailName
                    }}</a-tooltip>
                  </div>
                  <div>
                    <span class="pr10">
                      <FileDoneOutlined v-if="detail.resourceType == 3" />
                      <ClockCircleOutlined v-else />
                    </span>
                    <template v-if="detail.resourceType == 3">
                      <!-- 分钟 -->
                      {{ detail.times + $t("CM_Minute") }}
                    </template>
                    <template v-else-if="detail.resourceType == 9">
                      {{
                        formatSeconds(
                          parseInt(
                            new Date(detail.taskDetailEndTime * 1000) -
                              new Date(detail.taskDetailStartTime * 1000)
                          ) / 1000
                        )
                      }}
                    </template>
                    <template v-else>{{
                      formatSeconds(detail.times)
                    }}</template>
                  </div>
                  <div>
                    <template v-if="detail.resourceType == 3">
                      <!-- 考试用时 -->
                      {{ $t("Pub_Lab_ExamTime") }}：{{
                        formatSeconds(detail.learnTime)
                      }}
                    </template>
                    <template v-else>
                      <!-- 学习时长 -->
                      {{ $t("XB_LearningDuration") }}：{{
                        formatSeconds(detail.learnTime)
                      }}
                    </template>
                  </div>
                  <div>
                    <!-- 状态 -->
                    {{ $t("CM_Status") }}：
                    <template v-if="detail.resourceType == 3">
                      <span
                        class="status-field green"
                        v-if="detail.qualifier == 1"
                      >
                        <!-- 已通过 -->
                        {{ $t("Pub_Passed") }}({{ detail.score }})
                      </span>
                      <span class="status-field red" v-else>
                        <!-- 未通过 -->
                        {{ $t("CM_NoPass") }}({{ detail.score }})
                      </span>
                    </template>
                    <template v-else>
                      <span
                        class="status-field green"
                        v-if="detail.progress == 100"
                      >
                        <!-- 完成 -->
                        {{ $t("CM_Finish") }}
                      </span>
                      <span class="status-field red" v-else>
                        <!-- 未完成 -->
                        {{ $t("CM_NotCompleted") }}
                      </span>
                    </template>
                  </div>
                  <div v-if="detail.resourceType == 9">
                    {{ $t("Pub_Tab_Time") }}：{{
                      dateFormat(detail.taskDetailStartTime)
                    }}
                    -
                    {{ dateFormat(detail.taskDetailEndTime) }}
                  </div>
                  <div v-else>
                    {{ $t("Pub_Tab_Time") }}：{{
                      dateFormat(detail.startTime)
                    }}
                    -
                    {{ dateFormat(detail.lastTime) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a-collapse-panel>
      </a-collapse>
      <div class="page">
        <a-pagination
          size="small"
          :total="state.total"
          v-model:current="state.page"
          v-model:pageSize="state.pageSize"
          @change="getList"
        />
      </div>
    </template>
    <a-empty
      v-else
      imageStyle="height: 186px"
      :image="require('@/assets/image/no_data_3.png')"
    >
      <template #description>
        <span style="color: #bfbfbf">{{ $t("Pub_Lab_NoData") }}</span>
      </template>
    </a-empty>
  </div>
</template>

<script setup>
import { ref, reactive, watch } from 'vue';
import { dateFormat, formatSeconds } from "@/utils/tools";
import { getDetailType } from "@/utils/business";

const props = defineProps({
  list: {
    type: Array,
    default: () => {
      return [];
    },
  },
});

const collapseKey = ref(["0"]);
const state = reactive({
  showList: [],
  page: 1,
  pageSize: 10,
  total: 0,
});
function getList() {
  collapseKey.value = ["0"];
  const start = (state.page - 1) * state.pageSize;
  const end = start + state.pageSize;
  state.showList = props.list.slice(start, end);
}
watch(
  () => props.list,
  (newVal) => {
    if (newVal.length) {
      state.page = 1;
      state.total = newVal.length;
      getList();
    } else {
      state.total = 0;
      state.showList = [];
    }
  },
  { immediate: true, deep: true },
);
</script>

<style lang="less" scoped>
.tasks-wrap {
  ::v-deep(.ant-collapse-borderless > .ant-collapse-item) {
    border-bottom: 4px solid #fafafa;
  }

  ::v-deep(.ant-collapse-borderless) {
    background-color: #fff;
    border: 3px solid #fafafa;
  }

  ::v-deep(.ant-collapse-arrow) {
    padding: 3px;
    background-color: #e6e6e6;
    border-radius: 50%;
  }

  ::v-deep(.ant-collapse-borderless
      > .ant-collapse-item
      > .ant-collapse-content) {
    background-color: #fafafa;
  }
  .pr10 {
    padding-right: 10px;
  }

  .tasks-tit {
    .mixinFlex(flex-start; center);
  }

  .stages-box {
    background-color: #fafafa;

    .stages-tit {
      line-height: 45px;
      color: #404040;
    }

    .details {
      &-item {
        background-color: #fff;
        padding: 10px;
        line-height: 30px;
        .mixinFlex(space-between; center);
        flex-wrap: wrap;
        border-radius: 4px;
        margin-bottom: 4px;
        overflow: hidden;

        & > div {
          //   .mixinFlex(flex-start; center);

          &:nth-child(1) {
            width: 120px;
          }

          &:nth-child(2) {
            width: 200px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          &:nth-child(3) {
            width: 160px;
          }

          &:nth-child(4) {
            width: 150px;
          }

          &:nth-child(5) {
            width: 160px;
          }

          &:nth-child(6) {
            width: 320px;
          }
        }
      }
    }
  }
  .page {
    margin: 16px 0;
    text-align: right;
  }
}
</style>
